<template>
  <div>
    <app-navigation title="When" actionButtonIcon="fa fa-save" @action="handleAddTrigger"></app-navigation>

    <div v-if="description" class="description" v-html="description"></div>

    <span v-if="!hasCondition">
      <el-button v-loading="loading" style="margin: 15px" type="primary" icon="el-icon-plus" @click="addCondition">{{$t('triggers.add-condition')}}</el-button>

      <!--<el-button style="width: 50px; height: 50px; border-radius: 25px; display: flex; justify-content: center" icon="el-icon-plus"  @click="addCondition"></el-button>-->
    </span>


    <div v-else-if="showCondition && hasCondition && !loading">
      <condition-view :condition="condition" :key="condition.id" @changeCondition="changeCondition"></condition-view>
    </div>

  </div>

</template>

<script>
import Navigation from "@/components/Navigation";
import ConditionView from "./ConditionView";

export default {
  name: "When",

  components: {
    "app-navigation": Navigation,
    ConditionView,
  },

  data: function() {
    return {
      showCondition: true,
      description: "",
      loading: false,
    };
  },

  computed: {
    hasCondition: function() {
      return (
        this.$store.getters.triggerData.hasOwnProperty("condition") &&
        this.$store.getters.triggerData.condition.hasOwnProperty("id")
      );
    },

    removeCounter: function() {
      return this.$store.getters.triggerData.removeCounter;
    },

    condition: function() {
      return this.$store.getters.triggerData.condition;
    },
  },

  watch: {
    condition: {
      deep: true,
      handler: function(val) {
        console.log("TriggerCond", val);
      },
    },

    removeCounter: function(val) {
      console.log("counter", val);
      this.showCondition = false;
      this.$nextTick(() => {
        this.showCondition = true;
      });
    },
  },

  methods: {
    handleAddTrigger: function() {
      if (this.$route.params.edit) this.$router.go(-1);
      else this.$router.push({ name: "TriggerAdd" });
    },

    addCondition: function() {
      this.$store.commit("addCondition", 1);
    },

    changeCondition: function(description) {
      this.description = description;
      console.log("whendescription", this.condition.description);

      //this.$emit("changeCondition", this.condition.description)
    },

    fetchData() {
      this.loading = true;
      Promise.all([
        this.$store.dispatch("getAccessories"),
        this.$store.dispatch("getContacts"),
        this.$store.dispatch("getScenes"),
      ]).then((res) => {
        this.loading = false;
      });
    },
  },

  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
.description {
  display: flex;
  justify-content: center;
  margin: 10px 15px 25px 15px;
  padding: 15px;
  border: 1px solid #eeeeee;
  background-color: #fff;
}
</style>
