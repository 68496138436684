<template>
  <div class="root-container">

    <div class="header">

      <el-popover ref="opp-popover" trigger="click" v-if="isOperator">

        <div @click="showOperatorPopover" class="op-popover-ref" slot="reference">
          {{ $t('triggers.' + condition.symbol) }} <i class="el-icon-caret-bottom"></i>
        </div>

        <div class="op-popover-select" v-for="(operator, index) in operators" :key="operator.value" @click="handleSelectOperator(operator.value)">
          {{operator.title}}
        </div>

      </el-popover>


      <span v-if="isOperator">&nbsp;</span>
      <i class="el-icon-plus header-action-btn" @click="addCondition" @mouseover="onMouseOver" @mouseleave="onMouseLeave"></i>
      &nbsp;
      <i class="el-icon-close header-action-btn" @click="removeCondition"></i>

    </div>

    <div class="body">
      <div v-if="isOperator">
        <condition-view v-for="(item, index) in condition.conditions"
                        :key="item.id"
                        :condition="item"
                        @changeCondition="changeCondition">
        </condition-view>
      </div>
      <div v-else style="text-align: left">
        <comparator-view :condition="condition" @changeCondition="changeCondition"></comparator-view>
      </div>

    </div>

  </div>
</template>

<script>
  import {conditionMixin} from "./conditionMixin"
  import ConditionView from "./ConditionView"
  import ComparatorView from "./ComparatorView"

  export default {
    name: "ConditionView",

    components: {
      ConditionView,
      ComparatorView
    },

    mixins: [conditionMixin],

    computed: {
      isOperator: function () {
        const operator = this.operators.find(op => op.value === this.condition.symbol)
        return operator != undefined
      },
    },

    data: function () {
      return {
        visibleOppPopover: false,

        maxId: -1,

        operators: [
          { title: this.$t("triggers.AND"), value: "AND" },
          { title: this.$t("triggers.OR"), value: "OR" }
        ],

        boldPrefix: '<span><u>',
        boldPostfix: '</u></span>'
      }
    },



    methods: {

      showOperatorPopover: function () {
        console.log(this.visibleOppPopover)
        this.visibleOppPopover = !this.visibleOppPopover
      },

      handleSelectOperator: function (operator) {
        this.condition.symbol = operator
        this.visibleOppPopover = false

        this.changeCondition()

        this.$refs['opp-popover'].doClose();
      },



      addCondition: function () {
        this.maxId = -1
        this.calculateMaxConditionId(this.$store.getters.triggerData.condition)

        var newId = this.maxId + 1

        if (this.isOperator) {
          this.condition.conditions.push({ id: newId})
        } else {
          let copyData = {...this.condition}

          this.condition = {
            id: copyData.id,
            symbol: "AND",
            conditions: [
              {...copyData, id: newId}, {id: newId + 1}
            ]
          }
        }

        this.saveCondition()
      },



      removeCondition: function () {
        this.condition.removed = true
        this.$store.commit("removeCondition")
      },



      calculateMaxConditionId: function (condition) {
        this.maxId = Math.max(condition.id, this.maxId)

        if (condition.conditions) {
          condition.conditions.forEach(cond => {
            this.calculateMaxConditionId(cond)
          })
        }
      },

      changeCondition: function (description) {
        if (this.isOperator) {
          let desc = this.condition.conditions.map(condition => condition.description).join(` ${this.condition.symbol} `)
          this.condition.description = "( " + desc + " )"
        }

        console.log("description", this.condition.symbol, this.condition.description)

        this.$emit("changeCondition", this.condition.description)

      },

      onMouseOver: function () {
        this.resetStyle()
        this.condition.description = `${this.boldPrefix}${this.condition.description}${this.boldPostfix}`
        console.log("onMouseOver", this.condition.description)
        this.$emit("changeCondition", this.condition.description)
      },

      onMouseLeave: function () {
        this.resetStyle()
        console.log("onMouseLeave", this.condition.description)
        this.$emit("changeCondition", this.condition.description)
      },

      resetStyle: function () {
        this.condition.description = this.condition.description.replace(this.boldPrefix, "")
        this.condition.description = this.condition.description.replace(this.boldPostfix, "")
      }

    }
  }
</script>

<style scoped>
  @import "../css/style.css";
</style>