export const conditionMixin = {

  props: {
    condition: {}
  },

  data: function () {
    return {

    }
  },

  computed: {

  },

  methods: {
    getPath: function (a, obj) {
      console.log("getPath", a, obj)

      for(var key in obj) {
        if(obj.hasOwnProperty(key)) {
          if(key == "id" && a == obj[key]) return key;

          else if(obj[key] && typeof obj[key] === "object") {
            var path = this.getPath(a, obj[key]);
            if(path) return key + "." + path;
          }
        }
      }
    },

    saveCondition: function () {
      let condition = this.$store.getters.triggerData.condition
      let path = this.getPath(this.condition.id, condition)


      if (path.endsWith(".id"))
        path = path.replace(".id", '')
      else if (path.endsWith("id"))
        path = path.replace("id", '')

      console.log("before save", JSON.stringify(condition), path)

      this.$store.commit("saveCondition", {
        path: path,
        condition: this.condition
      })

      condition = this.$store.getters.triggerData.condition
      console.log("after save", JSON.stringify(condition), path)
    }
  },

}