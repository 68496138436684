<template>
  <div>

    <!--Select Category-->
    <el-select style="width: 120px" size="mini"
               v-model="selectedCategory"
               :placeholder="$t('triggers.select-category')">

      <el-option v-for="(cat, index) in categories"
                 :key="cat.value"
                 :label="$t(cat.title)"
                 :value="cat.value">
      </el-option>

    </el-select>


    <!--Select Identifier-->
    <el-select style="width: 120px" size="mini"
               v-if="existIdentifier && isIdArray"
               v-model="selectedId"
               :placeholder="$t('triggers.select')">

      <el-option v-for="(id, index) in ids[selectedCategory].list"
                 :key="id.value"
                 :label="id.title"
                 :value="id.value">
      </el-option>

    </el-select>


    <!--Input Identifier-->
    <el-input style="width: 120px" size="mini"
              v-model="selectedId"
              v-if="existIdentifier && isIdString">
    </el-input>


    <!--Select Property-->
    <el-select style="width: 120px" size="mini"
               v-if="showProperty"
               v-model="selectedProp"
               :placeholder="$t('triggers.select-prop')">

      <el-option v-for="(prop, index) in propertyList"
                 :key="prop.value"
                 :label="$t(prop.title)"
                 :value="prop.value">
      </el-option>

    </el-select>


    <!--Select Symbol (Comparator)-->
    <el-select style="width: 120px" size="mini"
               v-if="selectedProp != undefined"
               v-model="selectedSymbol"
               :placeholder="$t('triggers.select-comperator')">

      <el-option v-for="(symbol, index) in symbols"
                 :key="symbol.value"
                 :label="$t(symbol.title)"
                 :value="symbol.value">
      </el-option>

    </el-select>


    <!--Value Forms-->
    <span v-if="selectedSymbol != undefined">

      <!--Select value from list-->
      <el-select style="width: 120px" size="mini"
                 v-if="isValueArray"
                 v-model="selectedValue"
                 :placeholder="$t('triggers.select-value')">

        <el-option v-for="(value, index) in values"
                   :key="value.value"
                   :label="$t(value.title)"
                   :value="value.value">
        </el-option>

      </el-select>


      <!--Enter number value-->
      <el-input-number style="width: 120px" size="mini"
                       v-model="selectedValue"
                       v-else-if="isValueNumber">
      </el-input-number>


      <!--Enter string value-->
      <el-input style="width: 120px" size="mini"
                v-model="selectedValue"
                v-else-if="isValueString">
      </el-input>

    </span>


  </div>
</template>

<script>
import { conditionMixin } from "./conditionMixin";
import { categories, properties } from "@/utils/trigger-utils";
import {
  className,
  ACCESSORY_CATEGORY_PUNCH_BUTTON,
  ACCESSORY_CATEGORY_BINARY_BUTTON,
  ACCESSORY_CATEGORY_STATUS_DISPLAY,
} from "@/utils/accessory-utils";

export default {
  name: "ComparatorView",

  mixins: [conditionMixin],

  data: function() {
    return {
      selectedSymbol: this.condition.symbol,
      selectedCategory: this.condition.category,
      selectedId: this.condition.identifier,
      selectedProp: this.condition.property,
      selectedValue: this.condition.value,

      categories,
      properties,

      ids: {
        accessory: {
          type: "array",
          list: [
            { title: "Kapı Sensörü", value: 1, type: "HHAccessorySensor" },
            { title: "Gaz Sensörü", value: 2, type: "HHAccessorySensor" },
            { title: "Lambader", value: 3, type: "HHAccessorySwitch" },
            { title: "Giriş Aydınlatma", value: 4, type: "HHAccessorySwitch" },
            { title: "Dimmer", value: 5, type: "HHAccessoryDimmer" },
            {
              title: "Y.Odası Klima",
              value: 6,
              type: "HHAccesssoryThermostat",
            },
            {
              title: "Salon Klima",
              value: 7,
              type: "HHAccessoryAdvThermostat",
            },
            { title: "Panjur", value: 8, type: "HHAccessoryWindowCovering" },
            { title: "Tunable White", value: 9, type: "HHAccessoryTunableWhite" },
          ],
        },
        knxAddress: { type: "string" },
      },
    };
  },

  computed: {
    existIdentifier: function() {
      return this.ids[this.selectedCategory] != undefined;
    },

    isIdArray: function() {
      let id = this.ids[this.selectedCategory];
      return id.type == "array";
    },

    isIdString: function() {
      let id = this.ids[this.selectedCategory];
      return id.type == "string";
    },

    propertyList: function() {
      if (this.selectedId != undefined) {
        let idType = this.ids[this.selectedCategory];

        if (idType.type == "array") {
          let id = idType.list.find((id) => id.value == this.selectedId);
          return this.properties[id.type];
        }
      }

      return this.properties[this.selectedCategory];
    },

    getSelectedProp: function() {
      let props = this.propertyList;
      if (props == undefined) return undefined;

      return props.find((prop) => prop.value == this.selectedProp);
    },

    symbols: function() {
      let prop = this.getSelectedProp;
      return prop != undefined ? prop.comparators : [];
    },

    values: function() {
      let prop = this.getSelectedProp;
      return prop != undefined ? prop.values : [];
    },

    showProperty: function() {
      if (!this.selectedCategory) return false;

      if (this.existIdentifier) {
        return this.selectedId != undefined;
      }

      return true;
    },

    isValueArray: function() {
      let prop = this.getSelectedProp;
      if (prop == undefined) return false;

      return (
        prop.type === "array" &&
        prop.hasOwnProperty("values") &&
        Array.isArray(prop.values)
      );
    },

    isValueNumber: function() {
      let prop = this.getSelectedProp;
      if (prop == undefined) return false;

      return prop.type === "number";
    },

    isValueString: function() {
      let prop = this.getSelectedProp;
      if (prop == undefined) return false;

      return prop.type === "string";
    },
  },

  watch: {
    selectedCategory: function(val) {
      this.condition.category = this.selectedCategory;

      this.selectedId = undefined;
      this.selectedProp = undefined;
      this.selectedSymbol = undefined;
      this.selectedValue = undefined;

      if (this.updateCategoryDescription())
        this.$emit("changeCondition", this.condition.description);
    },

    selectedId: function(val) {
      this.condition.identifier = this.selectedId;

      this.selectedProp = undefined;
      this.selectedSymbol = undefined;
      this.selectedValue = undefined;

      if (this.updateIdDescription())
        this.$emit("changeCondition", this.condition.description);
    },

    selectedProp: function(val) {
      this.condition.property = this.selectedProp;

      this.selectedSymbol = undefined;
      this.selectedValue = undefined;

      if (this.updatePropDescription())
        this.$emit("changeCondition", this.condition.description);
    },

    selectedSymbol: function(val) {
      this.condition.symbol = this.selectedSymbol;

      this.selectedValue = undefined;

      if (this.updateSymbolDescription())
        this.$emit("changeCondition", this.condition.description);
    },

    selectedValue: function(val) {
      this.condition.value = this.selectedValue;

      if (this.updateValueDescription())
        this.$emit("changeCondition", this.condition.description);
    },
  },

  methods: {
    className,

    updateCategoryDescription: function() {
      console.log(
        "updateCategoryDescription",
        this.selectedCategory,
        this.selectedId,
        this.selectedProp,
        this.selectedSymbol,
        this.selectedValue
      );
      if (this.selectedCategory) {
        this.condition.description = this.$t(
          this.categories.find((cat) => cat.value == this.selectedCategory)
            .title
        );
        return true;
      }
      return false;
    },

    updateIdDescription: function() {
      console.log(
        "updateIdDescription",
        this.selectedCategory,
        this.selectedId,
        this.selectedProp,
        this.selectedSymbol,
        this.selectedValue
      );
      if (this.selectedId) {
        this.condition.description = this.$store.getters.accessories.find(
          (acc) => acc.id == this.selectedId
        ).name;
        return true;
      }
      return false;
    },

    updatePropDescription: function() {
      let prop = this.getSelectedProp;

      console.log(
        "updatePropDescription",
        this.selectedCategory,
        this.selectedId,
        this.selectedProp,
        this.selectedSymbol,
        this.selectedValue
      );
      if (prop) {
        if (this.existIdentifier && this.selectedId) {
          this.condition.description = this.$store.getters.accessories.find(
            (acc) => acc.id == this.selectedId
          ).name;
        } else {
          this.condition.description = this.$t(
            this.categories.find((cat) => cat.value == this.selectedCategory)
              .title
          );
        }

        this.condition.description += " " + this.$t(prop.title);
        return true;
      }
      return false;
    },

    updateSymbolDescription: function() {
      console.log(
        "updateSymbolDescription",
        this.selectedCategory,
        this.selectedId,
        this.selectedProp,
        this.selectedSymbol,
        this.selectedValue
      );
      let symbol = this.symbols.find(
        (symbol) => symbol.value == this.selectedSymbol
      );
      if (symbol) {
        this.updatePropDescription();

        this.condition.description += " " + this.$t(symbol.title);
        return true;
      }
      return false;
    },

    updateValueDescription: function() {
      console.log(
        "updateValueDescription",
        this.selectedCategory,
        this.selectedId,
        this.selectedProp,
        this.selectedSymbol,
        this.selectedValue
      );
      if (this.selectedValue) {
        this.updateSymbolDescription();

        if (this.values) {
          let value = this.values.find(
            (val) => val.value == this.selectedValue
          );
          console.log("value", value);
          if (value) {
            this.condition.description += " " + this.$t(value.title);
            return true;
          }
        } else {
          this.condition.description += " " + this.$t(this.selectedValue);
          return true;
        }
      }
      return false;
    },
    roomName(roomId) {
      const room = this.$store.getters.rooms.find((room) => room.id == roomId);
      return room ? room.name : roomId;
    },
  },

  created() {
    this.ids.accessory.list = this.$store.getters.accessories
      .filter(
        (accessory) =>
          accessory.category != ACCESSORY_CATEGORY_PUNCH_BUTTON &&
          accessory.category != ACCESSORY_CATEGORY_BINARY_BUTTON
      ) //accessory.category != ACCESSORY_CATEGORY_STATUS_DISPLAY
      .map((accessory) => {
        return {
          title: accessory.name + ' - ' + this.roomName(accessory.roomId),
          value: `${accessory.id}`,
          type: this.className(accessory.category),
        };
      });

    let contacts = this.$store.getters.contacts.map((contact) => {
      return {
        title: contact.name,
        value: `${contact.id}`,
      };
    });

    let scenes = this.$store.getters.scenes.map((scene) => {
      return {
        title: scene.name,
        value: `${scene.id}`,
      };
    });

    this.properties.intercom = this.properties.intercom.map((prop) => {
      return { ...prop, values: contacts };
    });

    this.properties.scene = this.properties.scene.map((prop) => {
      return { ...prop, values: scenes };
    });

    console.log("accessories", this.ids.accessory.list);

    if (this.updateValueDescription())
      this.$emit("changeCondition", this.condition.description);
  },
};
</script>

<style scoped>
</style>
