var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-navigation", {
        attrs: { title: "When", actionButtonIcon: "fa fa-save" },
        on: { action: _vm.handleAddTrigger }
      }),
      _vm.description
        ? _c("div", {
            staticClass: "description",
            domProps: { innerHTML: _vm._s(_vm.description) }
          })
        : _vm._e(),
      !_vm.hasCondition
        ? _c(
            "span",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticStyle: { margin: "15px" },
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.addCondition }
                },
                [_vm._v(_vm._s(_vm.$t("triggers.add-condition")))]
              )
            ],
            1
          )
        : _vm.showCondition && _vm.hasCondition && !_vm.loading
        ? _c(
            "div",
            [
              _c("condition-view", {
                key: _vm.condition.id,
                attrs: { condition: _vm.condition },
                on: { changeCondition: _vm.changeCondition }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }