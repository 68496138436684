var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "root-container" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _vm.isOperator
          ? _c(
              "el-popover",
              { ref: "opp-popover", attrs: { trigger: "click" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "op-popover-ref",
                    attrs: { slot: "reference" },
                    on: { click: _vm.showOperatorPopover },
                    slot: "reference"
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("triggers." + _vm.condition.symbol)) +
                        " "
                    ),
                    _c("i", { staticClass: "el-icon-caret-bottom" })
                  ]
                ),
                _vm._l(_vm.operators, function(operator, index) {
                  return _c(
                    "div",
                    {
                      key: operator.value,
                      staticClass: "op-popover-select",
                      on: {
                        click: function($event) {
                          return _vm.handleSelectOperator(operator.value)
                        }
                      }
                    },
                    [_vm._v("\n        " + _vm._s(operator.title) + "\n      ")]
                  )
                })
              ],
              2
            )
          : _vm._e(),
        _vm.isOperator ? _c("span", [_vm._v(" ")]) : _vm._e(),
        _c("i", {
          staticClass: "el-icon-plus header-action-btn",
          on: {
            click: _vm.addCondition,
            mouseover: _vm.onMouseOver,
            mouseleave: _vm.onMouseLeave
          }
        }),
        _vm._v("\n     \n    "),
        _c("i", {
          staticClass: "el-icon-close header-action-btn",
          on: { click: _vm.removeCondition }
        })
      ],
      1
    ),
    _c("div", { staticClass: "body" }, [
      _vm.isOperator
        ? _c(
            "div",
            _vm._l(_vm.condition.conditions, function(item, index) {
              return _c("condition-view", {
                key: item.id,
                attrs: { condition: item },
                on: { changeCondition: _vm.changeCondition }
              })
            }),
            1
          )
        : _c(
            "div",
            { staticStyle: { "text-align": "left" } },
            [
              _c("comparator-view", {
                attrs: { condition: _vm.condition },
                on: { changeCondition: _vm.changeCondition }
              })
            ],
            1
          )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }